import React from 'react';
import tw from 'twin.macro';

import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line

import HeaderBase, {
	NavLink,
	NavLinks,
	LogoLink,
	NavToggle,
	DesktopNavLinks,
} from '../light.js';

const Header = tw(
	HeaderBase,
)`max-w-none -mt-8 py-8 -mx-8 px-8 bg-primary-900 text-gray-100 drop-shadow-lg `;

const StyledHeader = styled(Header)`
	${tw`pt-8 max-w-none w-full`}
	${DesktopNavLinks} ${NavLink}, ${LogoLink} {
		${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
	}
	${NavToggle}.closed {
		${tw`text-gray-100 hover:text-primary-500`}
	}
`;

// const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`;

export default function NavBar({ modeSwitch }) {
	const navLinks = [
		<NavLinks key={1}>
			<NavLinks key={2}>
				<NavLink href='/'>Home</NavLink>
				<NavLink href='/contact-us'>Contact Us</NavLink>
			</NavLinks>
		</NavLinks>,
	];
	return <StyledHeader tw='fixed z-10 w-full' links={navLinks} />;
}
