import apiConfig from './config';
import axios from 'axios';

// Extract the first error message from the response
export const exceptionExtractError = (exception) => {
	if (!exception.Errors) return false;
	const errorKeys = Object.keys(exception.Errors);
	if (errorKeys.length > 0) {
		return exception.Errors[errorKeys[0]][0].message;
	}
	return false;
};

// Common Axios request function
export const fetchApi = async (
	endPoint,
	method = 'POST',
	payload = {},
	headers = { 'Content-Type': 'text/html' },
	timeout = 30000,
) => {
	const config = {
		method: method,
		url: apiConfig.url + endPoint,
		headers: headers,
		timeout: timeout,
		validateStatus: () => true, // Always consider the response status as valid
	};

	if (method === 'PUT' || method === 'POST') {
		config.data = payload;
	}

	try {
		const response = await axios(config);
		return response.data;
	} catch (error) {
		if (error.response && error.response.data) {
			// If the response contains JSON data, throw that as the error
			throw error.response.data;
		} else {
			throw error;
		}
	}
};
