const strings = {
	home: 'Home',
	aboutUs: 'About Us',
	contactUs: 'Contact Us',
	cancel: 'Cancel',
	send: 'Send',
	ok: 'Ok',
	// SPONSOR DIALOG
	selectThe: "You've selected the",
	pleaseSendContact: 'Plan, please send us your contact details.',
	sponsorSuccess: 'DETAILS SENT',
	detailsSent:
		"Thank you for your interest, we've successfully received your details and will get back to you shortly.",
	sponsorFail: 'DETAILS NOT SENT',

	getStarted: 'Get Started',
	detailsFail: "Sorry, we didn't receive your details. Please try again...",
	voteBundle: 'Select Vote Amount',
	cost: 'Cost',
	copyright: ' Copyright ',
	allRightReserved: ', Byte Limited. All Rights Reserved.',
};

export default strings;
