/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Container as ContainerBase } from 'global/misc/Layouts.js';
import logo from 'global/images/byte_logo_vote.png';
import { ReactComponent as FacebookIcon } from 'global/images/facebook-icon.svg';
// import { ReactComponent as TwitterIcon } from 'global/images/twitter-icon.svg';
import strings from 'global/localization/en';
// import { ReactComponent as YoutubeIcon } from 'global/images/youtube-icon.svg';

const Container = tw(ContainerBase)`bg-primary-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-10`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex flex-col items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-32`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
	${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
	svg {
		${tw`w-5 h-5`}
	}
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
export default () => {
	const currentYear = () => {
		var year = new Date();
		return year.getFullYear();
	};

	return (
		<Container>
			<Content>
				<Row>
					<LogoContainer>
						<LogoImg src={logo} />
						<LogoText>Byte Limited</LogoText>
					</LogoContainer>
					<SocialLinksContainer>
						<SocialLink href='https://facebook.com/bytelimited'>
							<FacebookIcon />
						</SocialLink>
					</SocialLinksContainer>
					<CopyrightText>
						&copy; {strings.copyright}
						{currentYear()}{' '}
						<a
							href='https://byteltd.com'
							target='_blank'
							rel='noopener noreferrer'>
							Byte Limited
						</a>{' '}
						| Powered by{' '}
						<a
							href='https://byteltd.com'
							target='_blank'
							rel='noopener noreferrer'>
							Byte Limited
						</a>
					</CopyrightText>
				</Row>
			</Content>
		</Container>
	);
};
