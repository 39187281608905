import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';

// import { ReactComponent as PlayIcon } from 'feather-icons/dist/icons/play-circle.svg';
// import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
import { ReactComponent as SvgDecoratorBlob1 } from '../../global/images/svg-decorator-blob-1.svg';
import { ReactComponent as SvgDecoratorBlob2 } from '../../global/images/dot-pattern.svg';
import DesignIllustration from '../../global/images/online_vote.svg';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl`;
const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
	${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
	${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const Hero = ({
	heading = 'Currently, there are no ongoing voting sessions for polls...',

	imageSrc = DesignIllustration,
	imageCss = null,
	imageDecoratorBlob = false,
}) => {
	return (
		<>
			<Container>
				<TwoColumn>
					<LeftColumn>
						<Heading>{heading}</Heading>
					</LeftColumn>
					<RightColumn>
						<IllustrationContainer>
							<img css={imageCss} src={imageSrc} alt='Hero' />
							{imageDecoratorBlob && <DecoratorBlob2 />}
						</IllustrationContainer>
					</RightColumn>
				</TwoColumn>
				<DecoratorBlob1 />
			</Container>
		</>
	);
};

export default Hero;
