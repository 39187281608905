import React from 'react';
import NomineesContainer from './NomineesContainer';
import tw from 'twin.macro';
import ScrollToTop from '../../global/helpers/ScrollToTop';
import { PrimaryButton as PrimaryButtonBase } from 'global/misc/Buttons.js';
import styled from 'styled-components';
import AnimationRevealPage from 'global/helpers/AnimationRevealPage';
import { useNavigate, useLocation } from 'react-router-dom';

const ActionButton = styled(PrimaryButtonBase)`
	${tw`block text-center text-sm font-semibold tracking-wider w-80 text-gray-100 bg-primary-500 px-6 py-4 rounded hover:bg-primary-700 focus:shadow-outline focus:outline-none transition-colors duration-300`}
`;

const HeaderContainer = tw.div`mt-20 lg:mt-20 w-full flex flex-col items-center`;
const Heading = tw.h1`text-3xl text-center sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none mt-12`;

export default function NomineeCat(props) {
	const location = useLocation();
	let data = location.state;
	const navigate = useNavigate();
	return (
		<AnimationRevealPage>
			<ScrollToTop />
			<HeaderContainer>
				<ActionButton as='a' onClick={() => navigate(-1)}>
					GO BACK
				</ActionButton>
				<Heading>{data.category.toUpperCase()}</Heading>
			</HeaderContainer>

			<NomineesContainer
				nominees={data.catData}
				pointBundles={data.pointBundles}
				showCatName={false}
			/>
		</AnimationRevealPage>
	);
}
