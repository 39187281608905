/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
// import { useSelector } from 'react-redux';
import NomineesContainer from './NomineesContainer';
import BackdropLoader from 'global/loaders/BackDropLoader';
import styled from 'styled-components';
import Categories from './Categories';
import { useLocation } from 'react-router-dom';
import { CountDownTimer } from 'global/functions/GlobaLFunctions';
import { useParams } from 'react-router-dom';
import * as api from 'global/services/session/api';
import { Box, Paper } from '@mui/material';
// import nomineeData from 'global/nomineeData.json';
// import bundles from 'global/bundles.json';

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Input = tw.input`border-2 px-4 w-1/2 py-6 rounded focus:outline-none -mt-8 font-medium transition duration-300 hocus:border-primary-500`;
const Heading = tw.h1`text-primary-900  text-3xl text-center sm:text-2xl md:text-2xl lg:text-2xl font-black leading-none mt-24`;

const Text = tw.h3`text-center sm:text-xl lg:text-2xl font-semibold leading-none mt-2 text-primary-900`;
const AnnouncementText = tw.h3`text-center sm:text-xl font-light leading-none mt-5`;
const AnnouncementContainer = tw.div`max-w-full overflow-x-auto`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-12 `;
const SwitchButton = styled.button`
	${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
	${(props) => props.active && tw`bg-primary-500 text-gray-100`}
`;

const Award = () => {
	const planDurations = [
		{
			text: 'Category',
			switcherText: 'All Categories',
		},
		{
			text: 'Nominees',
			switcherText: 'All Nominees',
		},
	];

	// ...

	const location = useLocation();
	let { code } = useParams();
	const [awardData, setAwards] = useState(location.state);

	// const user = useSelector((state) => state.user);
	const [voteData, setVoteData] = useState([]);
	// const [voteData, setVoteData] = useState(nomineeData);
	const [loader, setLoader] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [pointBundles, setPointBundles] = useState([]);
	// const [pointBundles, setPointBundles] = useState(bundles);
	const [activeDurationIndex, setActiveDurationIndex] = useState(
		awardData
			? awardData.has_category || awardData.has_sub_category
				? 0
				: 1
			: null,
	);

	useEffect(() => {
		if (code) {
			getPoll(`/api/poll/${code}`);
		} else {
			if (awardData) {
				setLoader(true);
				setVoteData(awardData.nominee_ids);
				setPointBundles(awardData.point_bundle_ids);
				setLoader(false);
			}
		}
	}, []);

	const getPoll = async (endPoint) => {
		setLoader(true);
		try {
			let response = await api.getPoll(endPoint);

			if (response) {
				let data = response.results[0];
				setLoader(false);
				setVoteData(data.nominee_ids);
				setAwards(data);
				setPointBundles(data.point_bundle_ids);
				setLoader(false);
			} else {
				setLoader(false);
				alert('Poll not found..please try again');
			}
		} catch (error) {
			setLoader(false);
			alert('Poll not found..please try again');
		}
	};

	const dynamicSearch = () => {
		return voteData.filter((nominees) =>
			nominees.name.toLowerCase().includes(searchTerm.toLowerCase()),
		);
	};

	let splitAnnouncement = null;

	if (awardData && awardData.announcement) {
		splitAnnouncement = awardData.announcement
			.split('\n')
			.map((line, index) => {
				const parts = line.split(': ');
				if (parts.length === 2) {
					const key = parts[0];
					const value = parts[1];
					if (value.includes('http')) {
						return (
							<div key={index}>
								<span style={{ fontWeight: 'bold' }}>{key}:</span>
								<a
									style={{ fontSize: 18 }}
									href={value}
									target='_blank'
									rel='noopener noreferrer'>
									{value}
								</a>
							</div>
						);
					} else {
						return (
							<div key={index}>
								<span style={{ fontWeight: 'bold' }}>{key}:</span> {value}
							</div>
						);
					}
				} else if (line.includes('http')) {
					return (
						<div key={index}>
							<a href={line} target='_blank' rel='noopener noreferrer'>
								{line}
							</a>
						</div>
					);
				}

				return <div key={index}>{line}</div>;
			});
	}

	const filterr = voteData
		.map((item) => item.category_name)
		.filter((value, index, self) => self.indexOf(value) === index);

	if (awardData) {
		return (
			<div>
				{loader ? <BackdropLoader /> : null}

				<HeaderContainer>
					<Heading>{`WELCOME TO ${awardData.name.toUpperCase()} `}</Heading>
					{awardData.vote_status === 'active' ? (
						<>
							<div tw='text-center sm:text-xl font-semibold leading-none mt-8 mb-8'>
								VOTING ENDS IN: <CountDownTimer date={awardData.end_date} />
							</div>
						</>
					) : (
						<>
							<Text>{awardData.inactive_message}</Text>
						</>
					)}
				</HeaderContainer>
				{!awardData.announcement ? null : (
					<Paper sx={{ p: 5, mt: 2, maxWidth: '60vh', margin: '0 auto' }}>
						<Text>UPDATE!</Text>
						<AnnouncementContainer>
							<AnnouncementText>{splitAnnouncement}</AnnouncementText>
						</AnnouncementContainer>
					</Paper>
				)}

				{awardData.vote_status === 'active' ? (
					<HeaderContainer>
						{awardData.has_category || awardData.has_sub_category ? (
							<>
								<PlanDurationSwitcher>
									{planDurations.map((planDuration, index) => (
										<SwitchButton
											active={activeDurationIndex === index}
											key={index}
											onClick={() => setActiveDurationIndex(index)}>
											{planDuration.switcherText}
										</SwitchButton>
									))}
								</PlanDurationSwitcher>
								<Heading>
									{activeDurationIndex === 1
										? 'Search Nominees'
										: 'Select a Category'}
								</Heading>
							</>
						) : null}
					</HeaderContainer>
				) : (
					<></>
				)}
				{awardData.vote_status === 'active' ? (
					<>
						{activeDurationIndex === 1 || awardData.has_category === null ? (
							<>
								<Box
									tw=' lg:px-72 items-center '
									my={4}
									style={{ marginTop: '4rem' }}>
									<Input
										placeholder='Search..'
										type='text'
										value={searchTerm}
										onChange={(e) => setSearchTerm(e.target.value)}
										margin='normal'
										autoFocus
										style={{ width: '100%', alignSelf: 'center' }}
									/>
								</Box>
								<div tw='' style={{ width: '100%' }}>
									<NomineesContainer
										nominees={dynamicSearch()}
										pointBundles={pointBundles}
										showCatName={true}
									/>
								</div>
							</>
						) : (
							<Categories
								catData={filterr}
								voteData={voteData}
								searchKey={'category_name'}
								pointBundles={pointBundles}
							/>
						)}
					</>
				) : (
					<></>
				)}
			</div>
		);
	} else {
		return (
			<div>
				<h4>Poll not found...</h4>
			</div>
		);
	}
};

export default Award;
