import AnimationRevealPage from 'global/helpers/AnimationRevealPage';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Categories from './Categories';

export default function Subcategory(props) {
	const location = useLocation();
	let data = location.state;

	const filterr = data.catData
		.map((item) => item.sub_category_name)
		.filter((value, index, self) => self.indexOf(value) === index);

	return (
		<AnimationRevealPage>
			<Categories
				catData={filterr}
				voteData={data.catData}
				searchKey='sub_category_name'
				heading={data.category}
				pointBundles={data.pointBundles}
			/>
		</AnimationRevealPage>
	);
}
