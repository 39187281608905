import WorkHours from 'global/forms/WorkingHours';
import ContactHero from 'global/headers/ContactHero';
import AddressMap from 'global/misc/AddressMap';
import React from 'react';

export default function Contact() {
	return (
		<div>
			<ContactHero />
			<WorkHours />
			<AddressMap />
		</div>
	);
}
