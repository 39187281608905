import React, { useEffect, useRef, useState } from 'react';
import Hero from './Hero';
import * as api from 'global/services/session/api';
import tw from 'twin.macro';
import { SectionHeading } from 'global/misc/Headings';
import AwardCard from 'global/cards/AwardCard';
import BackdropLoader from 'global/loaders/BackDropLoader';
import { Box, Grid } from '@mui/material';

const Heading = tw(SectionHeading)``;

const Home = () => {
	const [loader, setLoader] = useState(true); // Initialize with loader
	const [awards, setAwards] = useState([]);
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	const myRef = useRef(null);
	const executeScroll = () =>
		myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

	useEffect(() => {
		const handleOnlineStatusChange = () => {
			setIsOnline(navigator.onLine);
		};

		window.addEventListener('online', handleOnlineStatusChange);
		window.addEventListener('offline', handleOnlineStatusChange);

		return () => {
			window.removeEventListener('online', handleOnlineStatusChange);
			window.removeEventListener('offline', handleOnlineStatusChange);
		};
	}, []);

	useEffect(() => {
		if (isOnline) {
			getAwards();
		} else {
			setLoader(false);
		}
	}, [isOnline]);

	const getAwards = async () => {
		try {
			let response = await api.getAwards();

			if (response.count >= 1) {
				setAwards(response.results);
			}
		} catch (error) {
			// Handle any errors here
		} finally {
			setLoader(false);
		}
	};

	return (
		<div>
			{loader && <BackdropLoader />}
			{awards.length > 0 ? (
				<div>
					<Heading style={{ paddingTop: 80 }} ref={myRef}>
						All Polls
					</Heading>
					<Box sx={{ flexGrow: 1, m: 3, mt: -5 }}>
						<Grid
							container
							spacing={{ xs: 2, md: 3 }}
							columns={{ xs: 1, sm: 8, md: 12 }}>
							<AwardCard awards={awards} />
						</Grid>
					</Box>
				</div>
			) : ( (loader?<Hero executeScroll={executeScroll} heading=''/>: <Hero executeScroll={executeScroll}/>)
				
			)}
		</div>
	);
};

export default Home;
