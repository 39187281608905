import React, { useState, useEffect } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import '../styles/fab.css';

const FabTop = () => {
	const [visible, setVisible] = useState(false);

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		setVisible(scrolled > 300);
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisible);

		return () => {
			window.removeEventListener('scroll', toggleVisible);
		};
	}, []);

	return (
		<>
			{visible && (
				<button title='Back to top' className='scroll' onClick={scrollToTop}>
					<KeyboardArrowUpIcon style={{ color: 'white' }} />
				</button>
			)}
		</>
	);
};

export default FabTop;
