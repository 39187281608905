import moment from 'moment';
import React, { useEffect, useState } from 'react';

export const convertDate = (date) => {
	const dateMomentObject = moment(date, 'YYYY/MM/DD');
	const dateObject = dateMomentObject.toDate();
	return dateObject.toString().slice(0, 15);
};

const calculateTimeLeft = (targetDate) => {
	const currentDate = new Date();
	const difference = +new Date(targetDate) - currentDate;

	let timeLeft = {};

	if (difference > 0) {
		timeLeft = {
			days: Math.floor(difference / (1000 * 60 * 60 * 24)),
			hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
			minutes: Math.floor((difference / 1000 / 60) % 60),
			seconds: Math.floor((difference / 1000) % 60),
		};
	}

	return timeLeft;
};

export const CountDownTimer = ({ date }) => {
	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(date));

	useEffect(() => {
		const timer = setInterval(() => {
			const newTimeLeft = calculateTimeLeft(date);
			setTimeLeft(newTimeLeft);
		}, 1000);

		return () => clearInterval(timer);
	}, [date]);

	const timerComponents = Object.keys(timeLeft).map((interval) => {
		if (!timeLeft[interval]) {
			return null;
		}

		return (
			<span key={interval}>
				{timeLeft[interval]} {interval}{' '}
			</span>
		);
	});

	return (
		<div>
			{timerComponents.length ? timerComponents : <span>Time's up!</span>}
		</div>
	);
};
