import { fetchApi } from '../api';

const commonHeaders = {
	'Content-Type': 'text/html',
	clientId: '12345',
};

const shortTimeout = 30000;
const longTimeout = 900000;

//endpoint URLs
const endPoints = {
	signup: '/api/signup',
	resetPassword: '/api/app/reset_password',
	authenticate: '/api/auth/get_tokens',
	userUrl: '/api/res.users',
	voting: '/api/byte.app.vote/create',
	nominees: '/api/byte.vote.candidates',
	pointsBundle: '/api/byte.point',
	buyPoints: '/api/point.wallet.recharge',
	payPalTransfer: '/api/point.wallet.paypal.recharge',
	changePassword: '/api/app/change_password',
	getAwards: '/api/byte.vote.award',
	voucherVote: '/api/voucher.vote',
};

export const getAwards = () =>
	fetchApi(endPoints.getAwards, 'GET', {}, commonHeaders, shortTimeout);

export const getPoll = (endpoint) =>
	fetchApi(endpoint, 'GET', {}, commonHeaders, shortTimeout);

export const changePassword = (payload) =>
	fetchApi(
		endPoints.changePassword,
		'POST',
		payload,
		commonHeaders,
		longTimeout,
	);

export const rechargeWallet = (token, walletID, payload) =>
	fetchApi(
		`${endPoints.buyPoints}/${walletID}/recharge_wallet`,
		'POST',
		payload,
		commonHeaders,
		longTimeout,
	);

export const doStripeTransfer = (payload) =>
	fetchApi(endPoints.buyPoints, 'POST', payload, commonHeaders, longTimeout);

export const doVoucherVote = (payload) =>
	fetchApi(endPoints.voucherVote, 'POST', payload, commonHeaders, longTimeout);

export const doPaypalTransfer = (payload) =>
	fetchApi(
		endPoints.payPalTransfer,
		'POST',
		payload,
		commonHeaders,
		longTimeout,
	);

export const sendMessage = (payload) =>
	fetchApi(endPoints.contact, 'POST', payload, commonHeaders, shortTimeout);

export const authenticate = (email, password) =>
	fetchApi(
		endPoints.authenticate,
		'POST',
		{ db: 'vote', username: email, password: password },
		commonHeaders,
		shortTimeout,
	);

export const getPointsBundle = () =>
	fetchApi(endPoints.pointsBundle, 'GET', {}, commonHeaders, shortTimeout);

export const signup = (payload) =>
	fetchApi(endPoints.signup, 'POST', payload, commonHeaders, 120000);

export const getNominees = () =>
	fetchApi(endPoints.nominees, 'GET', {}, commonHeaders, 30000);

export const resetPassword = (payload) =>
	fetchApi(endPoints.resetPassword, 'POST', payload, commonHeaders, 30000);

export const getUserDetails = (userID, token) =>
	fetchApi(
		`${endPoints.userUrl}/${userID}`,
		'GET',
		{},
		{ ...commonHeaders, accessToken: token },
		30000,
	);

export const getRoommate = (roommateCode) =>
	fetchApi(
		`${endPoints.nominees}/${roommateCode}`,
		'GET',
		{},
		commonHeaders,
		30000,
	);

export const userVote = (payload, token) =>
	fetchApi(
		endPoints.voting,
		'POST',
		payload,
		{ ...commonHeaders, accessToken: token },
		30000,
	);

export const doActivation = (userID, token, payload) =>
	fetchApi(
		`${endPoints.userUrl}/${userID}/do_activation`,
		'POST',
		payload,
		{ ...commonHeaders, accessToken: token },
		60000,
	);

export const doTransfer = (walletID, token, payload) =>
	fetchApi(
		`${endPoints.buyPoints}/${walletID}/do_transfer`,
		'POST',
		payload,
		{ ...commonHeaders, accessToken: token },
		30000,
	);

export const doNewTransfer = (walletID, token, payload) =>
	fetchApi(
		`${endPoints.buyPoints}/${walletID}/do_new_transfer`,
		'POST',
		payload,
		{ ...commonHeaders, accessToken: token },
		30000,
	);

export const ubaTransfer = (walletID, token, payload) =>
	fetchApi(
		`${endPoints.buyPoints}/${walletID}/uba_transfer_complete`,
		'POST',
		payload,
		{ ...commonHeaders, accessToken: token },
		900000,
	);

export const verifyRave = (walletID, token, payload) =>
	fetchApi(
		`${endPoints.buyPoints}/${walletID}/verify_rave`,
		'POST',
		payload,
		{ ...commonHeaders, accessToken: token },
		900000,
	);

export const verifyPin = (walletID, token, payload) =>
	fetchApi(`${endPoints.buyPoints}/${walletID}/verify_pin`, 'POST', payload, {
		...commonHeaders,
		accessToken: token,
	});

export const verifyAddress = (walletID, token, payload) =>
	fetchApi(
		`${endPoints.buyPoints}/${walletID}/verify_address`,
		'POST',
		payload,
		{ ...commonHeaders, accessToken: token },
	);

export const verifyOtp = (walletID, token, payload) =>
	fetchApi(`${endPoints.buyPoints}/${walletID}/verify_otp`, 'POST', payload, {
		...commonHeaders,
		accessToken: token,
	});
