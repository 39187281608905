import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const backgroundImageUrl =
	'https://images.unsplash.com/photo-1587293852726-70cdb56c2866?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80';

const Item = styled(Paper)(({ theme }) => ({
	position: 'relative',
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(2),
	textAlign: 'center',
	color: '#fff',
	height: '25vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	background: `url(${backgroundImageUrl})`,
	'::before': {
		content: '""',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(162, 115, 255, 0.7)',
		zIndex: 1,
	},
}));

const TextContent = styled('div')({
	position: 'relative',
	zIndex: 2,
});

export default function ContactDetails() {
	return (
		<Box sx={{ flexGrow: 1, m: 3 }}>
			<Grid
				container
				spacing={{ xs: 2, md: 3 }}
				columns={{ xs: 1, sm: 8, md: 12 }}>
				<Grid item xs={2} sm={4} md={4}>
					<Item>
						<TextContent>
							<Typography sx={{ fontSize: 30, fontWeight: 'bold' }}>
								Address
							</Typography>
							<Typography mt={2} fontSize={20}>
								3 Upper Brook Street, Off Siaka Steven Street, Freetown, Sierra
								Leone
							</Typography>
						</TextContent>
					</Item>
				</Grid>
				<Grid item xs={2} sm={4} md={4}>
					<Item>
						<TextContent>
							<Typography sx={{ fontSize: 30, fontWeight: 'bold' }}>
								Get in Touch
							</Typography>
							<Typography mt={2} fontSize={20}>
								<strong>PHONE: </strong>
								<Link style={{ color: '#fff' }} to='tel:232.77.354.532'>
									232.77.354.532
								</Link>
							</Typography>
							<Typography mt={2} fontSize={20}>
								<strong>EMAIL: </strong>
								<Link style={{ color: '#fff' }} to='mailto:postit@byteltd.com'>
									contact@byteltd.com
								</Link>
							</Typography>
						</TextContent>
					</Item>
				</Grid>
				<Grid item xs={2} sm={4} md={4}>
					<Item>
						<TextContent>
							<Typography sx={{ fontSize: 30, fontWeight: 'bold' }}>
								Working Hours
							</Typography>
							<Typography mt={2} fontSize={20}>
								<strong>MON - FRI: </strong>9am - 5pm
							</Typography>

							<Typography mt={2} fontSize={20}>
								<strong>SUPPORT: </strong>Everyday, 24/7
							</Typography>
						</TextContent>
					</Item>
				</Grid>
			</Grid>
		</Box>
	);
}
