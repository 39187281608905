import React from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import Nominees from './Nominees';

const ThreeColumn = tw.div`flex flex-col items-center items-stretch lg:flex-row lg:flex-wrap`;
const NomineeContent = tw.div`lg:max-w-screen-xl mx-auto`;

export default function NomineesContainer({
	nominees,
	showCatName,
	pointBundles,
}) {
	return (
		<NomineeContent>
			{nominees && nominees.length ? (
				<ThreeColumn>
					{nominees.map((subNominee, index) => (
						<Nominees
							subNominee={subNominee}
							key={index}
							pointBundles={pointBundles}
							showCatName={showCatName}
						/>
					))}
				</ThreeColumn>
			) : null}
		</NomineeContent>
	);
}
