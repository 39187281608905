import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { PrimaryButton as PrimaryButtonBase } from 'global/misc/Buttons';

import { Link } from 'react-router-dom';
import { convertDate } from 'global/functions/GlobaLFunctions';

const AwardContainer = tw.div` mt-24 flex flex-col items-center items-stretch lg:flex-row lg:flex-wrap lg:justify-between`;

const Card = tw.div`mb-24 lg:mx-4 xl:mx-8 mx-4 sm:justify-between border flex-1 h-full max-w-sm flex flex-col rounded-tl-4xl rounded-br-5xl focus:outline-none`;
const CardImage = styled.div((props) => [
	`background-image: url("${props.imageSrc}");`,
	tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`,
]);

const TextInfo = tw.div`

  sm:pl-2
  `;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between`;
const Title = tw.h5`text-left text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div` my-2 sm:my-0`;
// const IconContainer = styled.div`
// 	${tw`inline-block  p-2 bg-gray-700 text-gray-100`}
// 	svg {
// 		${tw`w-3 h-3`}
// 	}
// `;
const Text = tw.div`text-base font-semibold text-gray-800`;

const PrimaryButton = tw(
	PrimaryButtonBase,
)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
const AwardCard = ({ awards }) => {
	return (
		<AwardContainer>
			{awards.map((award, index) => (
				<Card key={index}>
					<CardImage
						imageSrc={
							award.image_url
								? award.image_url
								: 'https://images.unsplash.com/photo-1604065985083-86231f74c233?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
						}
					/>
					<TextInfo>
						<TitleReviewContainer>
							<Title>{award.name}</Title>
						</TitleReviewContainer>
						<SecondaryInfoContainer>
							<IconWithText>
								<Text tw='text-primary-500 text-lg font-semibold'>
									VOTING ENDS: {convertDate(award.end_date)}
								</Text>
							</IconWithText>
						</SecondaryInfoContainer>
						{/* <SecondaryInfoContainer>
							<IconWithText>
								<Text tw='text-sm font-light'>
									<CountDownTimer date={award.end_date} />
								</Text>
							</IconWithText>
						</SecondaryInfoContainer> */}
						{award.description && (
							<Description>{award.description}</Description>
						)}
					</TextInfo>

					{award.vote_status === 'active' ? (
						<>
							<Link to={'/poll'} state={award}>
								<PrimaryButton>Vote Now</PrimaryButton>
							</Link>
						</>
					) : (
						<>
							<Link to={'/'} state={award}>
								<PrimaryButton>{award.inactive_message}</PrimaryButton>
							</Link>
						</>
					)}
				</Card>
			))}
		</AwardContainer>
	);
};

export default AwardCard;
