import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import VoteDialog from 'global/dialogs/VoteDialog';

const Column = tw.div`mt-16 lg:w-1/3`;
const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div((props) => [
	`background-image: url("${props.imageSrc}");`,
	tw`bg-contain bg-no-repeat bg-center h-64 lg:h-64 rounded rounded-b-none w-full`,
]);
const Details = tw.div`p-6 -mt-10 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const Title = tw.h5`mt-4 leading-snug font-bold lg:text-2xl`;
const HeaderTitle = tw.h5`mt-4 leading-snug font-bold lg:text-4xl`;

export default function Nominees({
	showCatName,
	subNominee,
	index,
	pointBundles,
}) {
	return (
		<Column key={index}>
			<Card>
				{subNominee.category_set && showCatName ? (
					<HeaderTitle
						style={{
							width: '80%',
							alignSelf: 'center',
							color: 'green',
						}}>
						{subNominee.category_name}
					</HeaderTitle>
				) : null}

				<Image
					style={{ width: '100%', alignSelf: 'center' }}
					imageSrc={subNominee.image_url}
				/>
				<Details>
					<Title style={{ width: '100%' }}>{subNominee.name}</Title>
					{subNominee.about ? (
						<div tw='text-sm font-semibold'>{subNominee.about}</div>
					) : null}
					{subNominee.sub_category_set === true ? (
						<Title style={{ width: '80%', alignSelf: 'center' }}>
							For {subNominee.sub_category_name}
						</Title>
					) : null}
					<VoteDialog subNominee={subNominee} pointBundles={pointBundles} />
				</Details>
			</Card>
		</Column>
	);
}
