import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton as PrimaryButtonBase } from 'global/misc/Buttons.js';

const ThreeColumnContainer = styled.div`
	${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;

const Column = styled.div`
	${tw`md:w-1/2 lg:w-1/2 flex`}
`;

export const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-10 lg:py-2`;

const Card = styled.a`
	${tw`flex flex-col mx-auto items-center w-full m-10 px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
	.textContainer {
		${tw`mt-6 text-center`}
	}

	.title {
		${tw`mt-2 font-bold lg:text-4xl leading-none text-primary-500 text-center`}
	}
`;

const HeaderContainer = tw.div`mt-20 lg:mt-20 w-full flex flex-col items-center`;
const Heading = tw.h1`text-3xl text-center sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none mt-12 text-secondary-500`;

const ActionButton = styled(PrimaryButtonBase)`
	${tw`block text-center text-sm font-semibold tracking-wider w-80 text-gray-100 bg-primary-500 px-6 py-4 rounded hover:bg-primary-700 focus:shadow-outline focus:outline-none transition-colors duration-300`}
`;

export default function Categories({
	catData,
	voteData,
	searchKey,
	pointBundles,
	heading,
}) {
	const navigate = useNavigate();

	const handleClick = (title) => {
		const dynamicSearch = () => {
			return voteData.filter((nominees) =>
				nominees[`${searchKey}`].includes(title),
			);
		};

		if (searchKey === 'category_name' && dynamicSearch()[0]?.sub_category_set) {
			navigate('/subcategory', {
				state: {
					catData: dynamicSearch(),
					category: title,
					pointBundles: pointBundles,
				},
			});
		} else {
			navigate('/nominees', {
				state: {
					catData: dynamicSearch(),
					category: title,
					pointBundles: pointBundles,
				},
			});
		}
	};

	return (
		<div>
			{heading ? (
				<HeaderContainer>
					<ActionButton as='a' onClick={() => navigate(-1)}>
						GO BACK
					</ActionButton>
					<Heading>{heading.toUpperCase()} CATEGORY</Heading>
				</HeaderContainer>
			) : null}

			<ThreeColumnContainer>
				{catData.map((title, i) => (
					<Column key={i}>
						<Card onClick={() => handleClick(title)}>
							<span className='title'>{title}</span>
						</Card>
					</Column>
				))}
			</ThreeColumnContainer>
		</div>
	);
}
