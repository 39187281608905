import React from 'react';
import {
	BrowserRouter as Router,
	Routes as Switch,
	Route,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Home from './home/Home';
import Contact from './contact/Contact';
import NotFound from './notFound/NotFound';
import Award from './home/Award';
import Subcategory from './home/Subcategory';
import NomineeCat from './home/NomineeCat';
// import NeaAward from './nea/NeaAward';

export default function Routes() {
	const history = createBrowserHistory();

	return (
		<Router history={history}>
			<Switch>
				<Route exact path='/' element={<Home />} />
				<Route path='/poll' element={<Award />} />
				<Route path='/poll/:code' element={<Award />} />
				<Route path='/subcategory' element={<Subcategory />} />
				<Route path='/nominees' element={<NomineeCat />} />
				<Route path='/contact-us' element={<Contact />} />
				<Route path='*' element={<NotFound />} />
			</Switch>
		</Router>
	);
}
