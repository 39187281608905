import React from 'react';
import 'tailwindcss/dist/base.css';
import './global/styles/globalStyles.css';
import { css } from 'styled-components/macro'; //eslint-disable-line
import AnimationRevealPage from './global/helpers/AnimationRevealPage';
import Routes from './pages/Routes';
import NavBar from './global/headers/navbar/NavBar';
import styled from 'styled-components';
import Footer from 'global/misc/Footer';
import ScrollToTop from 'global/helpers/ScrollToTop';

const StyledApp = styled.div`
	color: ${(props) => props.theme.fontColor};
`;
function App() {
	return (
		<AnimationRevealPage>
			<StyledApp>
				<NavBar />
				<Routes />
				<Footer />
				<ScrollToTop />
			</StyledApp>
		</AnimationRevealPage>
	);
}

export default App;
