import React, { useState, Fragment } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as api from 'global/services/session/api';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import BackdropLoader from 'global/loaders/BackDropLoader';
import MenuItem from '@mui/material/MenuItem';
import strings from '../localization/en';
import StripeCheckout from 'react-stripe-checkout';
import ReactDOM from 'react-dom';
import { TextField } from '@mui/material';
import logo from '../images/loading.gif'
const PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });

const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;

export default function VoteDialog({ subNominee, pointBundles }) {
	const [open, setOpen] = useState(false);

	const [success, setSuccess] = useState(false);
	const [loader, setLoader] = useState(false);
	const [bundle, setBundle] = useState(false);

	const [priceUSD, setPriceUSD] = useState(0);
	const [voucherDialog, setVoucherDialog] = useState(false);
	const [voucherCode, setVoucherCode] = useState('');
	const [errorMsg, setErrorMsg] = useState('');

	const [selectedValue, setSelectedValue] = useState('');

	const handleClose = () => {
		setVoucherCode('');
		setOpen(false);
		setVoucherDialog(false);
	};
	const handleFailed = () => {
		handleClose();
	};

	const handleSuccess = () => {
		setLoader(false);
		setSuccess(false);
		handleClose();
	};

	const onToken = (token) => {
		setLoader(true);
		api
			.doStripeTransfer({
				transaction_details: token,
				point_details: {
					point_bundle_id: bundle.id,
					amount: priceUSD,
					nominee: subNominee.id,
					points: bundle.points,
				},
			})
			.then((data) => {
				let response = JSON.parse(data);
				if (response.status === 'complete' && !response.error) {
					setLoader(false);
					setOpen(true);
					setSuccess(true);
					setVoucherDialog(false)
					return;
				} else {
					setLoader(false);
					setOpen(true);
					setSuccess(false);

					return;
				}
			})
			.catch(() => {
				setLoader(false);
				setOpen(true);
				setSuccess(false);

				return;
			});
	};

	const handleChange = (event) => {
		setSelectedValue(event.target.value);
	};

	const formatCurrency = (amount, currency) => {
		return (
			currency +
			' ' +
			amount
				.toFixed(2)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		);
	};

	// suseEffect(() => {getPointBundles()}, []);

	const createOrder = (data, actions) => {
		return actions.order.create({
			purchase_units: [
				{
					amount: {
						value: priceUSD,
					},
				},
			],
		});
	};

	const onApprove = (data) => {
		// sb-c8z9t3746554@personal.example.com
		// jwlb*9X-

		return api
			.doPaypalTransfer(
				JSON.stringify({
					data: {
						transaction_details: data,
						orderID: data.orderID,
						point_details: {
							point_bundle_id: bundle.id,
							amount: priceUSD,
							nominee: subNominee.id,
							points: bundle.points,
						},
					},
				}),
			)
			.then((data) => {
				let response = JSON.parse(data);
				if (response.status === 'complete' && !response.error) {
					setLoader(false);
					setOpen(true);
					setSuccess(true);
					setVoucherDialog(false);
					return;
				} else {
					setLoader(false);
					setOpen(true);
					setSuccess(false);

					return;
				}
			})
			.catch(() => {
				setLoader(false);
				setOpen(true);
				setSuccess(false);

				return;
			});
	};

	const handleVoucher = () => {
		let payload = {
			voucher_code: voucherCode,
			point_details: {
				point_bundle_id: bundle.id,
				amount: priceUSD,
				nominee: subNominee.id,
				points: bundle.points,
			},
		};
		setLoader(true);
		api
			.doVoucherVote(payload)
			.then((data) => {
				let response = JSON.parse(data);
				if (response.status === 'complete' && !response.error) {
					setLoader(false);
					setOpen(true);
					setSuccess(true);
					setVoucherDialog(false);
					return;
				}
				if (response.error_msg) {
					setErrorMsg(response.error_msg);
					setLoader(false);
					setOpen(true);
					setSuccess(false);
					return
				} else {
					setLoader(false);
					setOpen(true);
					setSuccess(false);
					return;
				}
			})
			.catch(() => {
				setLoader(false);
				setOpen(true);
				setSuccess(false);
				return;
			});
	};

	return (
		<div style={{ width: '100%' }}>
			<br />
			{loader ? <BackdropLoader /> : null}

			<FormControl tw='w-full'>
				<div>
					<Label style={{ color: '#fffff' }}>{strings.voteBundle}</Label>
				</div>

				<br />
				<Select
					style={
						bundle
							? { borderWidth: 3, borderColor: 'white' }
							: { borderWidth: 3, borderColor: 'red' }
					}
					value={selectedValue}
					onChange={handleChange}>
					{pointBundles.map((bundle, index) => {
						return (
							<MenuItem
								key={index}
								value={bundle.id}
								onClick={() => {
									setBundle(bundle);
									setPriceUSD(bundle.cost_usd);
								}}>
								{bundle.name}
							</MenuItem>
						);
					})}
				</Select>
				<span style={{ color: '#fffff' }}>
					{'Value: ' + formatCurrency(priceUSD, '$')}
				</span>

				<FormHelperText
					style={{
						fontWeight: 'bold',
						fontSize: '1rem',
						color: '#ffff',
					}}
				/>

				{bundle ? (
					<>
						<div style={{ width: '100%' }}>
							<div style={{ marginBottom: 20 }}>
								<Button
									variant='contained'
									color='primary'
									onClick={() => setVoucherDialog(true)}
									style={{ width: '100%' }}>
									Voucher Code
								</Button>
							</div>

							<StripeCheckout
								token={onToken}
								stripeKey={bundle.stripe_p_key}
								name={'Voting for ' + subNominee.name} // the pop-in header title
								image='https://i.ibb.co/t39QGwz/logo192.png' // the pop-in header image (default none)
								ComponentClass='div'
								allowRememberMe={false}
								panelLabel='Vote ' // prepended to the amount in the bottom pay button
								amount={priceUSD * 100} // cents
								currency='USD'
								opened={() => {
									setLoader(true);
								}}
								closed={() => {
									setLoader(false);
								}}>
								<Button
									variant='contained'
									color='primary'
									style={{ width: '100%' }}>
									Credit Card

								</Button>
							</StripeCheckout>
							<br />
							<div>
								<PayPalButton
									createOrder={(data, actions) => createOrder(data, actions)}
									onApprove={(data, actions) => onApprove(data, actions)}
								/>
							</div>
						</div>
					</>
				) : null}
			</FormControl>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='form-dialog-title'>
				<Fragment>
					<DialogTitle>
						{success ? 'Thank you for voting!' : 'VOTING FAILED'}
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{success
								? `You have successfully cast ${bundle.points} votes(s) for ${subNominee.name}`
								: errorMsg
								? errorMsg
								: 'Sorry, your vote could not be processed. Please try again.'}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							onClick={success ? handleSuccess : handleFailed}
							color='primary'>
							{success ? 'Done' : 'Ok'}
						</Button>
					</DialogActions>
				</Fragment>
			</Dialog>
			<Dialog
				open={voucherDialog}
				onClose={handleClose}
				aria-labelledby='form-dialog-title'>
				<Fragment>
					<DialogTitle>VOUCHER CODE</DialogTitle>
					<DialogContent>
						<DialogContentText>
							To vote, please enter the voucher code and tap vote.
						</DialogContentText>
						
						<TextField
							autoFocus
							value={voucherCode}
							margin='dense'
							label='Voucher Code'
							type='number'
							fullWidth
							variant='standard'
							inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
							onChange={(e) => setVoucherCode(e.target.value)}
						/>
					</DialogContent>
					<DialogActions>
					
						{loader?<>
							<img src={logo} alt="loading..." style={{alignContent: 'center', justifyContent:'center'}}/> 
						</>:<>
							<Button variant='contained' onClick={() => handleClose()}>
							Cancel
						</Button>
						<Button variant='contained' onClick={() => handleVoucher()}>
							Vote
						</Button>
						</>}
						
					</DialogActions>
				</Fragment>
			</Dialog>
		</div>
	);
}
